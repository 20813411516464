import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "profile-name-wrapper" }
const _hoisted_2 = { class: "flex no-wrap items-center q-gutter-xs" }
const _hoisted_3 = ["title"]
const _hoisted_4 = {
  key: 0,
  class: "verified-badge"
}
const _hoisted_5 = {
  key: 1,
  class: "text-muted"
}

import {PublicUser, User} from 'src/models/user';
import {Business} from 'src/models/business.model';
import {computed} from 'vue';
import CustomLink from 'components/etc/CustomLink.vue';

interface Props {
  owner: PublicUser | User | Business | undefined
  truncate?: boolean
  disableLink?: boolean,
  target?: string,
}


export default /*@__PURE__*/_defineComponent({
  __name: 'ProfileName',
  props: {
    owner: {},
    truncate: { type: Boolean },
    disableLink: { type: Boolean },
    target: {}
  },
  setup(__props: any) {

const props = __props

/***
 * Link to profile, either to business or private profile
 */
const toProfile = computed(() => {
  if (!props.owner) return

  if (props.owner?.is_business && 'slug' in props.owner) {
    return {name: 'business-profile', params: {slug: props.owner['slug']}}
  }
  return {name: 'profile', params: {uuid: props.owner?.uuid}}
})

const name = computed(() => {
  return props.owner?.public_name ?? props.owner?.name
})

return (_ctx: any,_cache: any) => {
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_tooltip = _resolveComponent("q-tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.owner?.uuid)
      ? (_openBlock(), _createBlock(CustomLink, {
          key: 0,
          to: toProfile.value,
          class: "relative-position cursor-pointer c-link-underline",
          style: {"text-decoration":"none"},
          "no-link": _ctx.disableLink,
          target: _ctx.target
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", {
                class: _normalizeClass([{'ellipsis': _ctx.truncate}, "name-wrapper"]),
                title: `${name.value}${_ctx.owner.is_verified ? ' ✓' : ''}`
              }, [
                _renderSlot(_ctx.$slots, "name", { user: _ctx.owner }, () => [
                  _createElementVNode("div", {
                    class: _normalizeClass([{'ellipsis': _ctx.truncate}, "text-h4 c-username"])
                  }, _toDisplayString(name.value), 3)
                ])
              ], 10, _hoisted_3),
              (_ctx.owner?.is_verified)
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                    _createVNode(_component_q_icon, {
                      class: "fas fa-check",
                      size: "0.8rem"
                    }),
                    _createVNode(_component_q_tooltip, null, {
                      default: _withCtx(() => _cache[0] || (_cache[0] = [
                        _createTextVNode("Verifiziert")
                      ])),
                      _: 1
                    })
                  ]))
                : _createCommentVNode("", true)
            ])
          ]),
          _: 3
        }, 8, ["to", "no-link", "target"]))
      : (_openBlock(), _createElementBlock("div", _hoisted_5, "Nutzer gelöscht"))
  ]))
}
}

})